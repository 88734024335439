<template>
  <div class="exam-list">
    <div class="title-box">
      <div class="title">
        <div class="dot"></div>
        <div>创建套餐</div>
      </div>
    </div>
    <div class="subtitle">基础规则</div>
    <div class="meal-name">
      <div style="color: #ec4747">*</div>
      <div style="color: #606266; margin: 0 8px 0 4px">套餐名称</div>
      <el-input v-model="mealName" placeholder="请输入名称" style="width: 600px" :minlength="1" :maxlength="30" show-word-limit></el-input>
    </div>
    <div class="subtitle">套餐商品列表</div>
    <div style="height: calc(100% - 355px); padding: 0 20px; position: relative">
      <el-table ref="multipleTable" :data="tableList" @selection-change="selectGoods" row-key="id" height="100%" :header-cell-style="{ fontWeight: 'normal', height: '60px', color: '#666', background: '#F6F6F6', fontSize: '16px' }" :cell-style="{ fontSize: '16px', color: '#333', height: '60px' }">
        <el-table-column prop="good_title" label="商品信息"></el-table-column>
        <el-table-column prop="sales_price" label="销售价"></el-table-column>
        <el-table-column align="right" type="selection"></el-table-column>
      </el-table>
      <div style="position: absolute; color: #666; font-size: 16px; top: 18px; right: 72px">套餐商品</div>
    </div>
    <div style="width: 100%; margin-top: 10px; padding: 0 20px; color: #656977">至少设置2个商品为必买品</div>
    <div class="footer-btn" style="margin-top: 40px; text-align: center">
      <el-button @click="cancelSure">取 消</el-button>
      <el-button type="primary" @click="sureSave">提 交</el-button>
    </div>
  </div>
</template>

<script>
import { goodsMealList, saveGoodsMeal, goodsMealInfo } from "@/utils/productService";

export default {
  name: "List",
  components: {},
  props: {},
  data() {
    return {
      mealName: "",
      tableList: [],
      checkedGoods: [],
    };
  },
  computed: {},
  methods: {
    getTableList() {
      goodsMealList().then((res) => {
        this.tableList = res.data;
      });
    },
    getDetails() {
      goodsMealInfo({ id: this.$route.query.id }).then((res) => {
        this.mealName = res.data.name;
        this.checkedGoods = res.data.goods_info;
        setTimeout(() => {
          this.$nextTick(() => {
            if (this.checkedGoods.length) this.toggleSelection();
          });
        }, 500);
      });
    },
    selectGoods(val) {
      this.checkedGoods = val;
    },
    cancelSure() {
      this.$router.push({ path: "/student/exam/productSerive/list" });
    },
    sureSave() {
      if (!this.mealName) return this.$message.warning("请填写套餐名称！");
      if (this.checkedGoods.length < 2) return this.$message.warning("至少设置2个商品为必买品！");
      let params = {
        name: this.mealName,
        goods_ids: this.checkedGoods.map((item) => item.id),
        id: this.$route.query.id,
        repeat_id: void 0,
      };
      saveGoodsMeal(params).then((res) => {
        console.log({ res });
        if (res.code === 200) {
          this.$message.success(res.msg);
          this.$router.push({ path: "/student/exam/productSerive/list" });
        } else if (res.code === 208) {
          this.$confirm(`您创建的套餐与已存在的套餐完全匹配。如果选择继续，将覆盖现有的套餐。您确定要覆盖该套餐吗？ `, "套餐重复提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            customClass: "green-message-box",
            type: "warning",
          }).then(() => {
            params.repeat_id = res.data.repeat_id;
            saveGoodsMeal(params).then((res) => {
              this.$message.success(res.msg);
              this.$router.push({ path: "/student/exam/productSerive/list" });
            });
          });
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
    toggleSelection() {
      console.log({ checkedGoods: this.checkedGoods });
      this.checkedGoods.forEach((row) => {
        this.$refs.multipleTable.toggleRowSelection(
          this.tableList.find((item) => {
            return row.id == item.id;
          }),
          true
        );
      });
    },
  },
  mounted() {
    this.getTableList();
    if (this.$route.query.id) this.getDetails();
  },
};
</script>

<style scoped lang="scss">
.exam-list {
  box-sizing: border-box;
  height: 100%;
  padding: 18px 30px;
  .title-box {
    margin: 20px 0 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000;
    font-size: 16px;
    .title {
      display: flex;
      align-items: center;
      .dot {
        width: 4px;
        height: 20px;
        background: #2dc079;
        border-radius: 2px;
        margin-right: 10px;
      }
    }
  }
  .subtitle {
    margin: 30px 0 20px 20px;
    color: #333;
  }
  .meal-name {
    display: flex;
    align-items: center;
    margin-left: 12px;
  }
  ::v-deep .gutter {
    background: #f6f6f6;
  }
}
</style>
